.container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #1e1e2e;
    color: #ffffff;
}

.title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ff9800;
}

.category {
    margin-bottom: 40px;
}

.category-title {
    font-size: 28px;
    margin-bottom: 15px;
    color: #ff5722;
    text-transform: uppercase;
    border-bottom: 2px solid #ff5722;
    display: inline-block;
    padding-bottom: 5px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 10px;
}

.grid-item {
    background-color: #2c2c3a;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
}

.grid-item:hover {
    transform: scale(1.05);
}

.grid-item h3 {
    font-size: 20px;
    color: #ff9800;
}

.grid-item p {
    font-size: 14px;
    margin-top: 5px;
    color: #cfcfcf;
}

code {
    display: block;
    background: #1b1b26;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    color: #ffcc00;
    font-family: "Courier New", monospace;
    font-size: 14px;
    text-align: left;
    white-space: pre-wrap;
}



button {
    padding: 10px;
    background-color: #345374;
    color: white;
    width: 100px;
    margin: 5px;
}

button:hover{
    background-color: rgba(255, 0, 0, 0.63);
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
