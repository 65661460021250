/* --- Updated Burp Suite Styles --- */
.burp-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #1e1e2e;
    color: #ffffff;
    border-radius: px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
}

.burp-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ff9800;
}

.burp-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 10px;
}

.burp-card {
    background-color: #2c2c3a;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out, background-color 0.3s ease;
}

.burp-card:hover {
    transform: scale(1.05);
    background-color: #3a3b4f;
}

.burp-card h2 {
    font-size: 20px;
    margin: 0;
    color: #ff9800;
}

.burp-steps {
    margin-top: 20px;
    padding: 20px;
    background-color: #24243a;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.burp-steps h2 {
    font-size: 22px;
    color: #ffcc00;
}

.burp-steps ul {
    text-align: left;
    list-style-type: square;
    padding-left: 20px;
}

.burp-steps li {
    margin: 10px 0;
    color: #cfcfcf;
}

.close-btn {
    background-color: #ff4444;
    color: white;
    padding: 12px 18px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    margin-top: 15px;
    transition: background-color 0.3s;
}

.close-btn:hover {
    background-color: #ff2222;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .burp-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .burp-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
